import React, { Component, Fragment } from "react";

//styles
import * as S from "./styles";
import { compose, transform } from "utils/image-utils";

class WorkshopImage extends Component {
  render() {
    const { pageContext } = this.props;
    const { workshop, workshopExtra } = pageContext;

    if (!workshop) {
      return null;
    }

    const resizeConfig = {
      w: 300
    };

    const { handle } = workshop.logo;

    const imageSrc = compose(
      handle,
      [transform("resize", resizeConfig), "compress"]
    );

    const { dates } = workshopExtra;

    return (
      <S.WorkshopImage>
        <S.Content>
          <S.ReactAcademy>{"<ReactAcademy/>"}</S.ReactAcademy>
          <S.Logo src={imageSrc} />
          <S.Title>{workshop.workshopType.title} Workshop</S.Title>
          <S.Location>
            {workshop.city}, {workshop.country}
            <S.Separator>•</S.Separator>{" "}
            {dates.map((d, index) => (
              <Fragment key={d}>
                <div>{d}</div>
                {index !== dates.length - 1 && <S.Separator>•</S.Separator>}
              </Fragment>
            ))}
          </S.Location>
        </S.Content>
      </S.WorkshopImage>
    );
  }
}

export default WorkshopImage;
