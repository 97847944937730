import emotion from '@emotion/styled';
import flex from 'styles/flex';
import { queries } from 'styles/responsive';

const gray = 'rgb(138, 138, 138) 74.6%';

export const WorkshopImage = emotion.div({
  ...flex.vertical,
  ...flex.centerVertical,
  overflow: 'hidden',
  backgroundImage: `radial-gradient( circle 1200px at 48.2% 50%,  rgba(255,255,255,1) 0%, ${gray})`,
  height: '100vh',
  backgroundColor: '#ffffff',
  [queries.belowLarge]: {
    backgroundImage: `radial-gradient( circle 800px at 48.2% 50%,  rgba(255,255,255,1) 0%, ${gray} )`
  }
});

export const Text = emotion.div({
  color: '#444444',
  fontWeight: 300,
  fontSize: 45,
  lineHeight: '50px',
  [queries.belowLarge]: {
    fontSize: 35,
    lineHeight: '35px'
  }
});

export const Title = emotion(Text)({
  ...flex.horizontal,
  ...flex.centerHorizontalV,
  marginBottom: 15,
  [queries.belowLarge]: {
    marginBottom: 10
  }
});

export const Location = emotion(Text)({
  ...flex.horizontal
});

export const Separator = emotion.div({
  margin: '0 15px',
  opacity: 0.5
});

export const Date = emotion(Text)({});

const white = '0,0,0';
const black = '255, 255, 255';

export const Overlay = emotion.div({
  backgroundColor: `rgba(${black},0.8)`,
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  zIndex: 999,
  width: '100vw',
  height: '100vh'
  // boxShadow: 'inset 0 0 80px 20px rgba(0,0,0,0.7)'
});

export const Content = emotion.div({
  ...flex.vertical,
  ...flex.centerVertical,
  position: 'absolute',
  zIndex: 99999,
  left: 0,
  top: 0,
  right: 0,
  bottom: 0
});

export const Logo = emotion.img({
  maxWidth: 300,
  maxHeight: 200,
  objectFit: 'fit',
  margin: '50px 0',
  [queries.belowLarge]: {
    margin: '30px 0'
  }
});

export const ReactAcademy = emotion(Text)({
  fontSize: 50
});
